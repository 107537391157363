<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                class="header"
                title="用户协议"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="div-text">
            <p class="title">{{markList.title}}</p>
            <div class="content" v-html="markList.content"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Agreement",
        data() {
            return {
                markList: [],
            }
        },
        created() {
            this.mark()
        },
        methods: {
            mark() {
                this.$axios.get('/illustration/index', {
                    params: {
                        mark: 'agreement'
                    }
                })
                    .then(res => {
                        this.markList = res.data.data
                    })
            }
        }
    }
</script>

<style scoped>
    .content{
        text-align: justify;
        line-height: 40px;
    }
    .title{
        margin-bottom: 40px;
        text-align: center;
    }
    .div-text {
        padding: 40px;
    }
</style>